import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { filter, groupBy, reduce } from 'lodash'

import { State } from './reducer'
import { Partner, PartnersByYears } from '../types/data'

export type PartnersState = {
	donators: Partner[]
	years: PartnersByYears
}

const initialState: PartnersState = {
	donators: [],
	years: {}
}

export const partnersSlice = createSlice({
	name: 'partners',
	initialState,
	reducers: {
		savePartnersList: (state: PartnersState, action: PayloadAction<{ donators: Partner[] }>) => {
			state.donators = filter(action.payload.donators, (item) => item.treesCount > 0 && !!item.logo)
			state.years = reduce(groupBy(action.payload.donators, 'year'), (result, item, year) => {
				return {
					...result,
					[year]: {
						oneTree: filter(item, partner => partner.treesCount === 1),
						multipleTrees: filter(item, partner => partner.treesCount > 1)
					}
				}
			}, {} as PartnersByYears)
		}
	}
})

export const getPartnersYears = (state: State) => state.partners.years
export const getPartners = (state: State) => state.partners.donators

export const partnersActions = partnersSlice.actions
