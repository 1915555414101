import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { keyBy } from 'lodash'
import { State } from './reducer'
import { Settings } from '../types/data'
import { SETTINGS_TYPE } from '../enums/common'

export type SettingsState = {
	[SETTINGS_TYPE.TREES_GOAL]: { goalValue: number }
	[SETTINGS_TYPE.TREES_PROGRESS]: { currentValue: number }
}

const initialState: SettingsState = {
	[SETTINGS_TYPE.TREES_GOAL]: { goalValue: 0 },
	[SETTINGS_TYPE.TREES_PROGRESS]: { currentValue: 2446 }
}

export const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		saveSettings: (state: SettingsState, action: PayloadAction<Settings>) => {
			const grouped = keyBy(action.payload, 'key')
			state[SETTINGS_TYPE.TREES_GOAL].goalValue = grouped[SETTINGS_TYPE.TREES_GOAL]?.value?.currentValue || 0
			state[SETTINGS_TYPE.TREES_PROGRESS].currentValue = grouped[SETTINGS_TYPE.TREES_PROGRESS]?.value?.currentValue || 0
		}
	}
})

export const getTreesCurrentValue = (state: State) => state.settings[SETTINGS_TYPE.TREES_PROGRESS]

export const settingsActions = settingsSlice.actions
