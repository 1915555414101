import React from 'react'
import cx from 'classnames'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ArticleHeading, Button, ParagraphLarge } from '@bratislava/susedia-components'

const SuccessImage = require('../../../assets/images/backgrounds/success-image.png')
const FailImage = require('../../../assets/images/backgrounds/fail-image.png')

const Wrapper = styled.div`
	padding: 64px;
	max-width: 1000px;
	
	&.success {
		background: right center no-repeat url("${SuccessImage}");
		background-color: ${({ theme }) => theme.colors.secondaryBackground};
	}
	
	&.fail {
		background: right center no-repeat url("${FailImage}");
		background-color: ${({ theme }) => theme.colors.errorBackground};
	}

	${ArticleHeading} {
		margin-bottom: 24px;
		width: 50%;
	}

	${ParagraphLarge} {
		margin-bottom: 48px;
		width: 50%;
	}

	@media(max-width: 992px) {
		padding: 64px;
		background-image: none !important;

		${ArticleHeading} {
			width: auto;
		}

		${ParagraphLarge} {
			width: auto;
		}
	}
`

type Props = {
	success: boolean
	onClose: () => void
}

const CancelResult = ({ success, onClose }: Props) => {
	const [t] = useTranslation()

	return (
		<Wrapper className={cx({ success, fail: !success })}>
			<ArticleHeading>
				{success
					? t('components|CancelResult.successTitle')
					: t('components|CancelResult.failTitle')
				}
			</ArticleHeading>
			<ParagraphLarge>
				{t('components|CancelResult.message')}
			</ParagraphLarge>
			<Button onClick={onClose}>
				{t('close')}
			</Button>
		</Wrapper>
	)
}

export default CancelResult
