import React from "react"
import HomePage from "../containers/Home/HomePage"

import { GetServerSideProps } from "next"

import { general, partners } from "../api"
import { setupStore } from "../redux"

import SEOHelmet from "../components/SEOHelmet"

import { settingsActions } from "../redux/settings"
import { partnersActions } from "../redux/partners"

type Props = {
	pathname: string
	shareTags: {}
	query: {
		success?: string
		successCancel?: string
	}
}

const HomePageComponent = ({ shareTags, query }: Props) => {
	return (
		<>
			<SEOHelmet pageMetadata={shareTags} />
			<HomePage
				success={query.success}
				successCancel={query.successCancel}
			/>
		</>
	)
}

export const getServerSideProps: GetServerSideProps = async ({
	req,
	query,
}) => {
	const shareTags = {
		url: `https://${req.headers.host}${req.url}`,
		title: "10 000 stromov | Hlavné mesto SR Bratislava",
		description: "Spravme Bratislavu zelenšou a príjemnejšou pre život",
		type: "article",
	}

	const store = setupStore()

	try {
		const { data } = await general.loadSettings()
		const { data: donatorsData } = await partners.loadPartners()

		store.dispatch(settingsActions.saveSettings(data.settings))
		store.dispatch(partnersActions.savePartnersList(donatorsData))
	} catch (e) {
		console.log(e)
	}

	return {
		props: {
			initialReduxState: store.getState(),
			shareHref: req.url,
			shareTags,
			pathname: req.url,
			query,
		},
	}
}

export default HomePageComponent
