import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"

import { map } from "lodash"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import Link from "next/link"
import { useRouter } from "next/router"

import {
	ArticleHeading,
	Button,
	CollectionView,
	IconsSection,
	Layout,
	Loading,
	LOADING_TYPE,
	MainHeading,
	MapTeaser,
	Modal,
	Paragraph,
	ProgressInfo,
	SegmentedControl,
	Space,
	SubHeading,
	WhereToPlant,
} from "@bratislava/susedia-components"

import { Col, Row, notification } from "antd"

import MENU_ITEM from "../../enums/menu"

import { blogPostsActions, getBlogPostsList } from "../../redux/blog"
import { getTreesCurrentValue } from "../../redux/settings"
import { menuActions } from "../../redux/menu"
import { getPartners } from "../../redux/partners"

import { uploadDocument, scrollToTop } from "../../utils/helpers"

import PageLayout from "../../components/Layout"
import ContributionResult from "./components/ContributionResult"
import CancelResult from "./components/CancelResult"
import { FORM_TYPE } from "../../enums/form"

import { form as formApi } from "../../api"

const { Section, Container } = Layout

const HomeBackground = require("../../assets/images/backgrounds/home-new-background.png")
const ExclamationIcon = require("../../assets/images/icons/exclamation-mark-circle.svg")
const QuestionIcon = require("../../assets/images/icons/question-mark-circle.svg")
const TreeIcon = require("../../assets/images/icons/tree-circle.svg")
const TwoTrees = require("../../assets/images/icons/two-trees.svg")
const MoreTrees = require("../../assets/images/icons/more-trees.svg")
const Wallet = require("../../assets/images/icons/wallet.svg")
const Tree = require("../../assets/images/icons/tree.svg")
const HandTree = require("../../assets/images/icons/hand-tree.svg")
const Building = require("../../assets/images/icons/building.svg")
const Mask1 = require("../../assets/images/backgrounds/mask-1.png")
const MapBackground = require("../../assets/images/backgrounds/map-background.jpg")
const Flag = require("../../assets/images/icons/flag.svg")
const WhereToPlantImage = require("../../assets/images/backgrounds/where-to-plant-image.png")

const StyledProgressInfo = styled(ProgressInfo)`
	position: absolute;
	bottom: -50px;
	right: calc((100vw - 1440px) / 2 + 60px);

	@media (max-width: 1440px) {
		right: 60px;
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: center;

		.progress,
		.goal {
			min-width: 262px;
		}
	}

	.button {
		margin: auto;
	}

	@media (min-width: 1400px) {
		min-width: 400px;
	}

	@media (max-width: 1400px) {
		position: relative;
		display: block;
		box-shadow: none;
		border-radius: 0;
		right: 0;
		bottom: -60px;

		.content {
			flex-direction: row;
			align-items: flex-end;

			.progress {
				flex: 1;
			}

			.goal {
				flex: 1;
				margin-left: 15px;
			}
		}

		.button {
			margin: 0;
		}
	}

	@media (max-width: 940px) {
		min-width: 320px;

		.content {
			flex-direction: column;
			align-items: center;

			.progress,
			.goal {
				display: flex;
				flex-direction: column;
				/* text-align: center; */
			}

			.goal {
				margin-left: 0;
				margin-top: 24px;
			}
		}

		.button {
			margin: auto;
		}
	}
`

const HeaderImage = styled.img`
	position: absolute;
	width: 100%;
	height: calc(100% + 150px);
	top: -150px;
	object-fit: cover;

	@media (max-width: 1400px) {
		height: calc(60% + 150px);
		object-position: 40% 50%;
	}

	@media (max-width: 930px) {
		height: calc(60% + 150px);
		top: -300px;
		object-position: 42% 100%;
	}

	@media (max-width: 600px) {
		height: calc(60% + 150px);
		top: -300px;
		object-position: 42% 100%;
	}
`

const StyledMainHeading = styled(MainHeading)`
	line-height: 100%;
	max-width: 600px;

	span {
		line-height: inherit;
	}

	@media (min-width: 1400px) {
		margin-bottom: 60px;
	}
`

const PartnerCol = styled(Col)<{ image: string }>`
	background: url("${({ image }) => image}") center no-repeat;
	background-size: contain;
	width: 150px;
	height: 80px;
	margin-bottom: 15px;
	margin-right: 15px;

	@media (max-width: 576px) {
		width: calc(33.333% - 20px);
		margin-right: 10px;
		margin-bottom: 5px;
	}
`

const HeaderButtons = styled.div`
	display: flex;
	gap: 1rem;

	@media (min-width: 1400px) {
		margin-bottom: 35px;
	}
`

type Props = {
	success?: string
	successCancel?: string
}

const HomePage = ({ success, successCancel }: Props) => {
	const [t] = useTranslation()
	const blogPosts = useSelector(getBlogPostsList)

	const partners = useSelector(getPartners)

	const displayedPartnerNames: string[] = []

	const filteredPartners = partners.filter((partner) => {
		if (
			!displayedPartnerNames.find(
				(displayedPartnerName) => displayedPartnerName === partner.name
			)
		) {
			displayedPartnerNames.push(partner.name)
			return true
		} else {
			return false
		}
	})

	const dispatch = useDispatch()
	const router = useRouter()
	const treesCount = useSelector(getTreesCurrentValue)

	const [paymentModal, setPaymentModal] = useState(success)
	const [cancelModal, setCancelModal] = useState(successCancel)

	const handleCloseModal = () => {
		router.push({ pathname: t("paths|ABOUT.path"), query: "" })
		setPaymentModal(undefined)
		setCancelModal(undefined)
	}

	const icons = useMemo(
		() => [
			{
				title: t("pages|HomePage.Icons.Why.title"),
				text: t("pages|HomePage.Icons.Why.text"),
				icon: { id: 0, originalFile: QuestionIcon },
			},
			{
				title: t("pages|HomePage.Icons.Plan.title"),
				text: t("pages|HomePage.Icons.Plan.text"),
				icon: { id: 0, originalFile: TreeIcon },
			},
			{
				title: t("pages|HomePage.Icons.How.title"),
				text: t("pages|HomePage.Icons.How.text"),
				icon: { id: 0, originalFile: ExclamationIcon },
			},
			{
				title: t("pages|HomePage.Icons.People.title"),
				text: t("pages|HomePage.Icons.People.text"),
				icon: { id: 0, originalFile: Flag },
			},
		],
		[t]
	)

	const progress = useMemo(
		() => [
			{
				title: "2019",
				text: t("pages|HomePage.Progress.yearProgress", {
					year: 2019,
					treesCount: 531,
					bushCount: 738,
				}),
				icon: { id: 0, originalFile: TwoTrees },
			},
			{
				title: "2020",
				text: t("pages|HomePage.Progress.yearProgress", {
					year: 2020,
					treesCount: 1389,
					bushCount: 4598,
				}),
				icon: { id: 0, originalFile: TwoTrees },
			},
			{
				title: "2021",
				text: t("pages|HomePage.Progress.yearProgress", {
					year: 2021,
					treesCount: 1207,
					bushCount: 1712,
				}),
				icon: { id: 0, originalFile: TwoTrees },
			},
			{
				title: t("pages|HomePage.Progress.goalTitle", { year: 2022 }),
				text: (
					<span
						dangerouslySetInnerHTML={{
							__html: t("pages|HomePage.Progress.goal", {
								interpolation: { escapeValue: false },
							}),
						}}
					/>
				),
				icon: { id: 0, originalFile: Flag },
			},
		],
		[t]
	)

	const progressInfo = useMemo(
		() => ({
			content: {
				progressTrees: {
					title: "Od roku 2019 sme vysadili navyše",
					icon: {
						id: 0,
						smallSize: TwoTrees,
						originalFile: TwoTrees,
					},
					value: "3 657",
					text: "vzrastlých stromov",
				},
				progressBushes: {
					icon: {
						id: 0,
						smallSize: TwoTrees,
						originalFile: TwoTrees,
					},
					value: "25 263",
					text: "kríkov",
				},
				goal: {
					title: t("pages|HomePage.ProgressInfo.goal.title"),
					icon: {
						id: 0,
						smallSize: MoreTrees,
						originalFile: MoreTrees,
					},
					value: "20 600",
					text: "sadeníc",
				},
			},
			// button: {
			// 	title: t("pages|HomePage.ProgressInfo.button.title"),
			// 	action: () => router.push(t("paths|MAP.path")),
			// },
		}),
		[t]
	)

	const segments = useMemo(
		() => [
			{
				icon: Wallet,
				text: t("pages|HomePage.How.moneyText"),
				callback: () => router.push(t("paths|CONTRIBUTE.money.path")),
			},
			{
				icon: Tree,
				text: t("pages|HomePage.How.treeText"),
				callback: () => router.push(t("paths|CONTRIBUTE.tree.path")),
			},
			{
				icon: HandTree,
				text: t("pages|HomePage.How.volunteerText"),
				callback: () =>
					router.push(t("paths|CONTRIBUTE.volunteer.path")),
			},
			{
				icon: Building,
				text: t("pages|HomePage.How.instituteText"),
				callback: () =>
					router.push(t("paths|CONTRIBUTE.institution.path")),
			},
		],
		[t]
	)

	const handleSubmit = async (submitData: any) => {
		const formData = {
			type: FORM_TYPE.WHERE_TO_PLANT,
			recaptcha: process.env.NEXT_PUBLIC_RECAPTCHA || "",
			form: submitData,
		}

		try {
			await formApi.sendForm(formData)

			notification.success({
				message: t("components|DynamicForm.success"),
				description: t("components|DynamicForm.successDescription"),
			})

			return Promise.resolve()
		} catch (e) {
			notification.error({
				message: t("components|DynamicForm.error"),
				description: t("components|DynamicForm.errorDescription"),
			})

			return Promise.reject({})
		}
	}

	useEffect(() => {
		scrollToTop()
		dispatch(blogPostsActions.loadBlogList({ limit: 6 }))
		dispatch(menuActions.select(MENU_ITEM.ABOUT))
	}, [])

	return (
		<PageLayout>
			<Section marginTop={{ xs: 30, sm: 30, md: 60 }}>
				<HeaderImage src={HomeBackground} alt={"Home"} />
				<Container className={"wide"}>
					<StyledMainHeading>
						<span
							style={{ color: "white", lineHeight: "130%" }}
							dangerouslySetInnerHTML={{
								__html: "Mesto robíme prirodzene<br/> krajším",
							}}
						/>
					</StyledMainHeading>
					<HeaderButtons>
						<Link href={t("paths|CONTRIBUTE.path")}>
							<a href={t("paths|CONTRIBUTE.path")}>
								<Button>
									{t("pages|HomePage.Header.supportTitle")}
								</Button>
							</a>
						</Link>
						<Link href="https://10000stromov.sk/ako-sa-zapojit">
							<a href="https://10000stromov.sk/ako-sa-zapojit">
								<Button buttonType="secondary">
									Chcem zasadiť
								</Button>
							</a>
						</Link>
					</HeaderButtons>
				</Container>
				<StyledProgressInfo {...progressInfo} />
			</Section>
			<Section>
				<Container id={"progress"}>
					<IconsSection content={progress} />
				</Container>
				<Container className={"narrow"}>
					<SubHeading>
						{t("pages|HomePage.About.subtitle")}
					</SubHeading>
					<ArticleHeading>
						{t("pages|HomePage.About.title")}
					</ArticleHeading>
				</Container>
				<Container>
					<IconsSection content={icons} />
				</Container>
				<Container className={"narrow"}>
					<SubHeading>Zapojte sa</SubHeading>
					<ArticleHeading>
						{t("pages|HomePage.How.title")}
					</ArticleHeading>
					<Paragraph style={{ margin: "32px 0", fontWeight: 600 }}>
						{t("pages|HomePage.How.text")}
					</Paragraph>
					<SegmentedControl content={segments} />
					<Space height={32} />
					<Button buttonType={"secondary"}>
						<Link href={t("paths|INFO.path")}>
							{t("pages|HomePage.How.moreButtonTitle")}
						</Link>
					</Button>
				</Container>
			</Section>
			<Section>
				<Container>
					<WhereToPlant
						apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS ?? ""}
						sitekey={process.env.NEXT_PUBLIC_RECAPTCHA ?? ""}
						image={WhereToPlantImage}
						onSubmit={handleSubmit}
						onUpload={uploadDocument}
					/>
				</Container>
			</Section>
			{/* <Section>
				<Container className={"narrow"}>
					<SubHeading>{t("pages|HomePage.Map.subtitle")}</SubHeading>
					<ArticleHeading>
						{t("pages|HomePage.Map.title")}
					</ArticleHeading>
				</Container>
				<Container className={"wide"}>
					<MapTeaser
						buttonTitle={t("pages|HomePage.Map.buttonTitle")}
						href={t("paths|MAP.path")}
						mapBackground={MapBackground}
					/>
				</Container>
			</Section> */}

			<Section>
				<Container className={"narrow"}>
					<SubHeading>{t("pages|HomePage.Blog.subtitle")}</SubHeading>
					<ArticleHeading>
						{t("pages|HomePage.Blog.title")}
					</ArticleHeading>
				</Container>
				<Container>
					{blogPosts.isLoading && (
						<Loading type={LOADING_TYPE.ARTICLES} />
					)}
					{!!blogPosts.data?.blogPosts && (
						<CollectionView
							maskImage={Mask1}
							type={"subArticle"}
							items={blogPosts.data.blogPosts}
							urlPath={t("paths|BLOG.path")}
							linkComponent={Link}
						/>
					)}
					<Link href={t("paths|BLOG.path")}>
						<a href={t("paths|BLOG.path")}>
							<Button className={"center"}>
								{t("pages|HomePage.Blog.showMore")}
							</Button>
						</a>
					</Link>
				</Container>
			</Section>
			<Section>
				<Container>
					<Row gutter={[30, 30]} align={"middle"} justify={"center"}>
						{map(filteredPartners, (partner) => (
							<PartnerCol
								key={partner.name}
								image={partner.logo.originalFile}
							/>
						))}
					</Row>
					<Space height={15} />
					<Link href={t("paths|PARTNERS.path")}>
						<a href={t("paths|PARTNERS.path")}>
							<Button className={"center"}>
								{t("pages|HomePage.Partners.showMore")}
							</Button>
						</a>
					</Link>
				</Container>
			</Section>
			<Modal visible={!!paymentModal} onHide={handleCloseModal}>
				<ContributionResult
					onClose={handleCloseModal}
					success={paymentModal === "true"}
				/>
			</Modal>
			<Modal visible={!!cancelModal} onHide={handleCloseModal}>
				<CancelResult
					onClose={handleCloseModal}
					success={successCancel === "true"}
				/>
			</Modal>
		</PageLayout>
	)
}

export default HomePage
