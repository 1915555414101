import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { State } from './reducer'
import { StateListType, StateType } from '../types/state'
import { Article, ArticleDetail } from '../types/data'

import { blogPosts } from '../api'

export type BlogPostsState = {
	list: StateListType<{ blogPosts: Article[] }>
	detail: StateType<ArticleDetail>
}

const initialState: BlogPostsState = {
	list: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	detail: {
		data: null,
		isLoading: false,
		isFailure: false
	}
}

const loadBlogList = createAsyncThunk('blog/fetchList',
	async (filters: object) => {
		const { data } = await blogPosts.loadBlogPosts(filters)

		return data
	}
)

export const blogPostsSlice = createSlice({
	name: 'blogPosts',
	initialState,
	reducers: {
		loadBlogDetail: (state: BlogPostsState, action: PayloadAction<ArticleDetail>) => {
			state.detail.data = action.payload
		}
	},
	extraReducers: builder => {
		builder.addCase(loadBlogList.pending, (state) => {
			state.list = { ...state.list, isLoading: true, isFailure: false }
		})

		builder.addCase(loadBlogList.rejected, (state) => {
			state.list = { ...state.list, isLoading: false, isFailure: true }
		})

		builder.addCase(loadBlogList.fulfilled, (state, { payload }) => {
			state.list = { ...state.list, isLoading: false, isFailure: false, data: payload }
		})
	}
})

export const getBlogPostsList = (state: State) => state.blogPosts.list
export const getBlogPostDetail = (state: State) => state.blogPosts.detail

export const blogPostsActions = {
	...blogPostsSlice.actions,
	loadBlogList
}
